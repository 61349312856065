<template>
  <Base title="批文清单" titleIcon="el-icon-s-claim">
  <el-dialog title="操作日志" @close="logs = []" v-model="showLogHistory">
    <LogHistory :data="logs"></LogHistory>
  </el-dialog>
  <div>
    <div class="search-row">
      <el-input placeholder="名称" v-model="filter.keyword" style="width: 500px">
        <template #prepend> 关键字 </template>
      </el-input>
      <el-button type="primary" style="margin-left: 5px" @click="() => {
        filter.pageNum = 1;
        query();
      }
        ">查询</el-button>
      <el-button type="primary" @click="manage(null)">创建批文</el-button>
    </div>
  </div>

  <el-table :data="pagerData.list" v-loading="isLoading">
    <el-table-column label="发文机构" prop="doc_Org" width="140" align="center">
      <template #default="{ row: item }">
        {{ dicLabel(item.doc_Org, docOrgDic) }}
      </template>
    </el-table-column>
    <el-table-column label="批复文号" prop="doc_Compile_No" width="240">
      <template #default="{ row: item }">
        {{ (item.isMuliNo ? `(${item.muliNoKey})` : "") + item.doc_Compile_No }}
      </template>
    </el-table-column>
    <el-table-column label="批复标题" prop="doc_Title">
      <template #default="{ row: item }">
        <DocViewBtn :doc="item" @show="showDocContent(item.id)"></DocViewBtn>
      </template>
    </el-table-column>
    <el-table-column label="批复类型" prop="doc_Category_Name" width="100" align="center">
    </el-table-column>
    <el-table-column prop="doc_Release_Unit" label="相关项目数" width="100" align="center"></el-table-column>
    <el-table-column label="状态" width="100">
      <template #default="{ row: item }">
        {{ dicLabel(item.approved, docStatusDic) }}
      </template>
    </el-table-column>
    <el-table-column label="唯一性" width="100" v-if="user.inRoles(roles.prov)">
      <template #default="{ row: item }">
        {{ item.isUnique ? "是" : "否" }}
      </template>
    </el-table-column>
    <el-table-column label="日志" width="80">
      <template #default="{ row: item }">
        <el-button type="text" icon="el-icon-copy-document" @click="doQueryHistory(item.id)">日志
        </el-button>
      </template>
    </el-table-column>
    <el-table-column label="操作" width="200">
      <template #default="{ row: item }">
        <el-button-group>
          <el-button v-if="(item.new_User_Id == user.id && item.approved == 0) ||
            (user.inRoles(roles.prov) && item.approved == 2)
            " size="mini" type="primary" icon="el-icon-edit" @click="manage(item.id)"></el-button>
          <el-button type="primary" icon="el-icon-check" size="mini" v-if="item.approved == 1 && user.inRoles(roles.prov)"
            @click="showDocContent(item.id, true)"></el-button>
          <el-popconfirm title="确认删除吗!?" v-if="item.doc_Release_Unit == 0 &&
            (user.inRoles(roles.prov) ||
              (item.new_User_Id == user.id && item.approved == 0))
            " @confirm="doRemove(item)">
            <template #reference>
              <el-button type="danger" icon="el-icon-delete" size="mini" :disabled="isProcessing"></el-button>
            </template>
          </el-popconfirm>

          <el-button v-if="item.doc_Category_Name == '方案' && user.inRoles(roles.prov)
            " plain type="primary" :icon="item.isUnique ? 'el-icon-lock' : 'el-icon-unlock'" size="mini"
            @click="doToggleUnique(item)"></el-button>
        </el-button-group>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination style="padding-left: 5px" @current-change="handleCurrentChange" @size-change="handleSizeChange"
    v-model:page-size="filter.pageSize" :total="pagerData.count" layout="sizes,total, prev, pager, next"
    v-model:currentPage="filter.pageNum"></el-pagination>
  <DocContent :setting="showSetting" @changeApprove="doChangeStatus" v-if="showSetting.visible"></DocContent>
  <Manage :setting="manageSetting" @change="query" v-if="manageSetting.visible"></Manage>
  </Base>
</template>

<script lang="ts">
import roles from "@/types/roles.ts";
import Manage from "./manage.vue";
import LogHistory from "@/views/shared/logHistory.vue";
import { defineComponent, inject, ref, onMounted, reactive, toRefs } from "vue";
import {
  useQueryOfficialDocs,
  useOfficialDoc,
  DocComponents,
  useQueryActionHistory
} from "@/network/officialDoc.ts";
import { dicLabel, docStatusDic, docOrgDic } from "@/network/lookUp.ts";
import Base from "@/views/layout/Base.vue";
export default defineComponent({
  components: { Base, ...DocComponents, Manage, LogHistory },
  setup() {
    const user: any = inject("user");

    const showLogHistory = ref(false);
    const [logs, queryHistory] = useQueryActionHistory();
    const doQueryHistory = (id) => {
      showLogHistory.value = true;
      queryHistory(id);
    };


    const manageSetting = reactive({
      visible: false,
      id: null,
    });

    const filter = reactive({
      pageNum: 1,
      pageSize: 20,
      keyword: null,
    });
    const manage = (id) => {
      manageSetting.id = id;
      manageSetting.visible = true;
    };

    const showSetting = reactive({
      visible: false,
      id: null,
      approve: false,
    });
    const showDocContent = (id, approve = false) => {
      showSetting.visible = true;
      showSetting.id = id;
      showSetting.approve = approve;
    };
    const [isLoading, pagerData, query] = useQueryOfficialDocs(filter);
    const [isProcessing, , , , changeStatus, toggleUnique, remove] =
      useOfficialDoc();

    const doRemove = ({ id }) => {
      remove(id).then((p) => query());
    };
    const doChangeStatus = ({ id, status, memo }) => {
      showSetting.visible = false;
      changeStatus(id, status, memo).then((p) => query());
    };
    const doToggleUnique = ({ id }) => {
      toggleUnique(id).then((p) => query());
    };
    onMounted(() => {
      query();
    });

    return {
      logs,
      queryHistory,
      showLogHistory,
      doQueryHistory,
      dicLabel,
      docStatusDic,
      docOrgDic,
      user,
      roles,
      manage,
      manageSetting,
      showSetting,
      showDocContent,
      isLoading,
      pagerData,
      query,
      filter,
      isProcessing,
      doChangeStatus,
      doToggleUnique,
      doRemove,
      handleCurrentChange: (e) => {
        filter.pageNum = e;
        query();
      },
      handleSizeChange: (e) => {
        filter.pageSize = e;
        query();
      },
    };
  },
});
</script>

<style scoped></style>